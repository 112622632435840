.info-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.info-page-wrapper {
  background: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-page-title {
  font-size: 32px;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  border-bottom: 2px solid #eee;
  padding-bottom: 15px;
}

.info-page-content {
  font-size: 16px;
  line-height: 1.6;
  color: #444;
}
