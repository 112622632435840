@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');

.brand-section-wrapper {
  overflow: hidden; /* Hide overflowing content */
  background-color: black;
  padding: 1.5rem; /* Adjust as needed */
}

.brand-section {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 2rem; /* Space between logos */
  animation: scroll 10s linear infinite; /* Infinite scrolling */
}

.brand-logo {
  height: 2rem; /* Adjust logo height */
}

input[type='range']::-webkit-slider-thumb {
  @apply w-4 h-4 bg-white rounded-full cursor-pointer;
  appearance: none;
}

input[type='range']::-moz-range-thumb {
  @apply w-4 h-4 bg-white rounded-full cursor-pointer;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* login css */

.custom-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  position: relative;
  text-align: center;
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.auth-buttons button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.facebook-button {
  background: #3b5998;
  color: white;
}

.google-button {
  background: #db4437;
  color: white;
}

.divider {
  margin: 20px 0;
  font-size: 14px;
  color: #888;
}

.form {
  margin-top: 20px;
}

form input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.continue-button {
  width: 100%;
  background: #5c452b;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.terms {
  font-size: 12px;
  color: #888;
}

.terms a {
  color: #5c452b;
}

.email-note {
  background: #f9f9f9;
  padding: 10px;
  font-size: 12px;
  color: #888;
  margin-top: 20px;
}
